import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Gallery from "../views/Gallery.vue";
import RoofSealing from "../views/RoofSealing.vue";
import RoofTarring from "../views/RoofTarring.vue";
import Yeriot from "../views/Yeriot.vue";
import ExposedWalls from "../views/ExposedWalls.vue";
import Super560 from "../views/Super560.vue";
import Testimonials from "../views/Testimonials.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "catchAll",
    component: Home,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/roof-sealing",
    name: "RoofSealing",
    component: RoofSealing,
  },
  {
    path: "/roof-tarring",
    name: "RoofTarring",
    component: RoofTarring,
  },
  {
    path: "/yeriot",
    name: "Yeriot",
    component: Yeriot,
  },
  {
    path: "/exposed-walls",
    name: "ExposedWalls",
    component: ExposedWalls,
  },
  {
    path: "/lastik-560",
    name: "Super560",
    component: Super560,
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Testimonials,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
