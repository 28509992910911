<template>
  <div>
    <Hero />
    <Contact />
    <v-row align="center" justify="center" class="my-10">
      <h1 class="gallery-title">גלריית עבודות</h1>
    </v-row>
    <v-container class="mb-16">
      <v-row>
        <v-col v-for="n in 9" :key="n" class="d-flex child-flex" cols="4">
          <v-img
            :src="`/assets/Gallery/${n}.jpg`"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <!-- <Posts /> -->
  </div>
</template>

<script>
import Hero from "../components/Hero";
import Contact from "../components/Contact";
// import Posts from "../components/Posts";
export default {
  name: "Home",
  components: {
    Hero,
    Contact,
    // Posts,
  },

  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped>
.gallery-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.gallery-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 160px;
  margin: auto;
}
</style>
