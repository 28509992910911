<template>
  <div>
    <Hero />
    <Contact />
    <About />
    <Posts />
    <Contact />
  </div>
</template>

<script>
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import About from "../components/About";
import Posts from "../components/Posts";
export default {
  name: "Home",
  components: {
    Hero,
    Contact,
    About,
    Posts,
  },

  data() {
    return {};
  },
  computed: {},
};
</script>

<style>
</style>
