<template>
  <div id="contact" style="">
    <v-container align="center">
      <v-row style="justify-content:center" align="center">
        <v-btn class="my-6 py-10" style="color:gray" text href="tel:+972 52-570-6611">
          <h1 :style="isMobile ? 'font-size: 2rem;' : 'font-size: 3rem;'">052-570-6611</h1>
        </v-btn>
      </v-row>
      <v-row style="color:gray;justify-content:center" align="center">
        <h4>או</h4>
      </v-row>
      <v-row align="center" v-show="status == 0">
        <v-text-field
          label="שם מלא"
          v-model="contactDetails.fullName"
          type="text"
          name="fullName"
          outlined
          dense
          class="mx-4 mt-7"
        ></v-text-field>
        <v-text-field
          label="מספר טלפון"
          v-model="contactDetails.phone"
          type="number"
          name="phone"
          outlined
          dense
          class="mx-4 mt-7"
        ></v-text-field>
        <v-text-field
          label="איזור בארץ"
          v-model="contactDetails.area"
          type="text"
          name="area"
          outlined
          dense
          class="mx-4 mt-7"
        ></v-text-field>

        <v-btn
          color="primary"
          v-show="!loading"
          @click="() => send()"
          class="ml-4"
          >צור קשר</v-btn
        >
        <v-progress-circular
          v-show="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-row align="center" v-show="status != 0 && status != 200" class="mb-1">
        <h1 style="color: gray">
          לצערינו, לא הצלחנו לקבל את פנייתך. אנא נסה שנית.
        </h1>
        <v-spacer></v-spacer>
        <v-btn x-large tile color="#5068a9" @click="resetform" text>
          חזרה
          <v-icon right> mdi-keyboard-backspace </v-icon>
        </v-btn>
      </v-row>
      <v-row align="center" v-show="status == 200" class="mb-1">
        <h1 style="color: gray">תודה, פנייתכם התקבלה ונחזור אליכם בהקדם.</h1>
        <v-spacer></v-spacer>
        <v-btn x-large tile color="#5068a9" @click="resetform" text>
          חזרה
          <v-icon right> mdi-keyboard-backspace </v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Contact",

  data() {
    return {
      // rules: [
      //   (value) => !!value || "Required.",
      //   (value) => (value && value.length >= 3) || "Min 3 characters",
      // ],
      loading: false,
      contactDetails: { fullName: "", phone: "", area: "" },
      status: 0,
    };
  },
  methods: {
    send() {
      this.loading = true;
      axios
        .post("", this.contactDetails)
        .then((response) => {
          this.status = response.status;
          this.loading = false;
          // console.log(response.status);
        })
        .catch((error) => {
          this.status = error.status;
          this.loading = false;
          // console.log(error);
        });
    },
    resetform() {
      this.status = 0;
      this.contactDetails.fullName = "";
      this.contactDetails.phone = "";
      this.contactDetails.area = "";
    },
  },
  computed:{
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

<style></style>
