<template>
  <div class="hero--container">
    <v-carousel class="carousel--container" cycle hide-delimiters>
      <v-carousel-item
        v-for="(item, i) in isMobile ? mobileItems : items"
        :key="i"
        :src="item.src">
        <v-row class="fill-height" align="center" justify="center"> </v-row>
      </v-carousel-item>
    </v-carousel>
    <img :height="isMobile ? '100px' : 'auto'" class="team-banner" src="/assets/team.png" />
  </div>
</template>

<script>
export default {
  name: "Hero",

  data() {
    return {
      items: [
        {
          src: "/assets/carousel/1.jpg",
          title: "10 שנות אחראריות!",
        },
        {
          src: "/assets/carousel/2.jpg",
          title: "משנת 1968, דור שלישי לאיטום",
        },
        {
          src: "/assets/carousel/3.jpg",
          title: "10 שנות אחראריות!",
        },
        {
          src: "/assets/carousel/4.jpg",
          title: "10 שנות אחראריות!",
        },
      ],
      mobileItems: [
        {
          src: "/assets/carousel/mobile/1.gif",
          title: "10 שנות אחראריות!",
        },
        {
          src: "/assets/carousel/mobile/2.gif",
          title: "משנת 1968, דור שלישי לאיטום",
        },
        {
          src: "/assets/carousel/mobile/3.gif",
          title: "10 שנות אחראריות!",
        },
        {
          src: "/assets/carousel/mobile/4.gif",
          title: "10 שנות אחראריות!",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
  .team-banner {
    position: absolute;
    bottom: 0;
    right: 5%;
    transform: translateY(40%) rotateZ(20deg);
  }
  .carousel--container {
    /* overflow: visible; */
  }
  .hero--container {
    position: relative;
    overflow-y: visible;
  }
</style>
