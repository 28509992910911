<template>
  <div style="width: 100%;">
    <v-navigation-drawer v-model="drawer" app absolute right temporary hide-overlay>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="/assets/avatar.jpg" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>מיכאל איטום</v-list-item-title>
            <v-list-item-subtitle>איטום קירות וגגות</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense nav dark>
        <v-list-item v-for="(link, i) in links" :key="i" link :to="link.to">
          <v-list-item-content>
            <v-list-item-title @click="drawer = !drawer" style="color: black">{{
      link.title
    }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="
      $vuetify.goTo('#contact', {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      })
      ">
          <v-list-item-content>
            <v-list-item-title @click="drawer = !drawer" style="color: black">
              צור קשר
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar flat hide-on-scroll style="overflow: hidden">
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-side-icon>
        <v-img src="/assets/logo.jpg" title="Logo" max-width="200px"></v-img>
      </v-toolbar-side-icon>

      <v-toolbar-items style="width: 100%">
        <v-tabs class="navtabs hidden-md-and-down " color="#5068a9" active-class="activetab" slider-color="transparent"
          height="100%" align-with-title>
          <v-tab v-for="(link, i) in links" :key="i" :to="link.to" @click="
      $vuetify.goTo(1, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      })
      ">
            {{ link.title }}
          </v-tab>
          <v-btn text tile large height="100%" color="#757575" @click="
      $vuetify.goTo('#contact', {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      })
      ">
            צור קשר
          </v-btn>
        </v-tabs>
        <v-spacer></v-spacer>
        <div class="hidden-sm-and-down" style="width:100px; display: flex; flex-direction: column; justify-content: center; color: #5c8ece;">
          <h5>
            עם ישראל
          </h5>
          <h5>
            ביחד ננצח
          </h5>
        </div>
        <img class="hidden-sm-and-down" src="/assets/Israel_flag.svg" height="60%" style="align-self:center" />
        <v-btn class="mr-2" color="#5068a9" href="tel:+972 52-570-6611" text>
          <div class="hidden-sm-and-down" :style="{fontSize: '2rem' }">052-570-6611</div>
          <v-icon right dark large> mdi-phone </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "Header",

  data: () => ({
    drawer: null,
    links: [
      { title: "עמוד הבית", to: "/" },
      // { title: "גלרייה", to: "/gallery" },
      { title: "יריעות ביטומניות", to: "/yeriot" },
      { title: "זיפות גגות", to: "/roof-tarring" },
      { title: "איטום קירות חיצוניים", to: "/exposed-walls" },
      { title: "איטום בסיקה לסטיק 560", to: "/lastik-560" },
      { title: "ממליצים", to: "/testimonials" },
    ],
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped>
.activetab {
  color: #5068a9;
  text-decoration-color: #5068a9 !important;
}
</style>