<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col cols="12" md="6" align-self="center">
          <v-img
            src="/assets/ViewHero/ZiputGagot.jpg"
            max-height="500px"
            title="זיפות גגות"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Header -->
          <div style="text-align: center; max-width: 250px" class="">
            <h1 class="page-title">זיפות גגות</h1>
          </div>
          <!-- Paragraph -->

          זיפות גגות עמיד בפני חדירת מים למשך 5 שנים לפחות, זאת בתנאי שמסיידים
          את הגג עם חיזוק של דבק בכל שנה. <br />סיוד הגג הוא הכרחי למניעה מקרני
          השמש לייבש את הזפת יתר על המידה, שכן ייבוש זה יוצר סדקים בזפת דרכם מים
          יכולים לחדור.<br />
          זיפות גגות נחשב לאיטום אמין וזול יותר (בטווח הקצר) מאופציות אחרות כגון
          איטום ביריעות ביטומניות, איטום במאסטר גג וכד'.<br /><br />
          <strong> שלבי אטימת גג בזפת:<br /> </strong>
          אם תשתית הגג היא בטון, מורחים פריימר לפני ביצוע האיטום. את עבודת
          הזיפות יש לבצע על הפריימר כדי לקשר בין הבטון לזפת. <br />
          עוד לפני ביצוע האיטום בזפת, מרימים מזגנים, קולטי שמש וכו', וממקמים
          אותם על משטח למניעת מגע עם הזפת, וכן יש לקלף מקומות בעייתיים, ולאטום
          סדקים ואזורים מקולפים ע"י שכבת זפת או ביטומן אלסטומרי קר.<br />
          מעל שכבת זפת ראשונה, יש ליישם רשת, ואז על רשת זו למרוח זפת בשנית, כך
          שהרשת נמצאת בין שתי השכבות.<br />
          תשומת לב מיוחדת ניתנת למעקה התחתון בדפנות הגג (רולקות) אשר יש להקפיד
          לאטום עם זפת שכן אזור זה רגיש במיוחד לחדירת מים<br /><br />

          לאחר זיפות גגות ניתנת אחריות בכתב עד ל-5 שנים בתנאי הכרחי - לסייד את
          הגג כל שנה.
          <br /><br />
          מיכאל משתמש בזפת חדשה תו תקן 25/75 וגם הוא מחמם את הזפת במיכל סגור
          בשביל למנוע ריח רע וזיהום סביבתי.
        </v-col>
      </v-row>
    </v-container>
    <Contact />

    <Posts />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import Posts from "../components/Posts";
export default {
  name: "RoofSealing",
  components: {
    Contact,

    Posts,
  },

  data() {
    return {};
  },
  computed: {},
};
</script>

<style>
.ttt {
  font-family: "Rubik" !important;
  font-weight: "700";
  font-size: 1.5rem;
}
.page-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.page-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 160px;
  margin: auto;
}
</style>
