<template>
  <v-app>
    <!-- App Bar + Navigation Drawer -->
    <v-app-bar app flat color="white">
      <Header />
    </v-app-bar>

    <v-main>
      <!-- Active View -->
      <router-view></router-view>
      <!-- Whatsapp Widget -->
      <div class="elfsight-app-636f7d93-8b27-48b6-b375-2a4691beac06"></div>
    </v-main>

    <v-footer>
      <!-- Footer Component -->
      <Footer></Footer>
      <!-- FAB Scroll to top -->
      <v-btn
        color="#5068a9"
        dark
        fab
        fixed
        bottom
        right
        @click="
          $vuetify.goTo(1, {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
          })
        "
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
* {
  font-family: "Rubik";
}
</style>