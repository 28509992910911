<template>
  <div>
    <v-container class="my-10">
      <v-row align="center" justify="center">
        <h1 class="posts-title text-center">השירותים שלנו</h1>
      </v-row>
      <v-row class="mt-16">
        <v-col
          cols="12"
          md="4"
          v-for="item in items"
          justify="center"
          align="center"
          :key="item.id"
        >
          <v-card
            :class="'mx-2' + isMobile ? '' : 'mb-16'"
            max-width="400px"
            outlined
            hover
          >
            <div class="img-div">
              <v-img
                class="white--text align-end postimg"
                height="200px"
                :src="item.src"
                :title="item.title"
              >
                <v-card-title class="text-div">{{ item.title }}</v-card-title>
              </v-img>
            </div>

            <v-card-subtitle class="pb-0 text-right">{{
              item.subtitle
            }}</v-card-subtitle>

            <v-card-text class="text--primary text-right">
              <div>{{ item.description }}</div>
            </v-card-text>

            <v-card-actions>
              <!-- <router-link :to="item.to"> -->
              <v-btn
                class="mr-auto"
                color="primary"
                text
                @click="
                  () => {
                    $router.push(item.to);
                    $vuetify.goTo(1, {
                      duration: 300,
                      offset: 0,
                      easing: 'easeInOutCubic',
                    });
                  }
                "
              >
                המשך לקרוא
              </v-btn>
              <!-- </router-link> -->
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" justify="center" align="center"> </v-col>
        <v-col cols="12" md="4" justify="center" align="center">
          <div class="img-div">
            <v-img
              class="logo-img"
              src="/assets/linkedin_banner_image_2.png"
              height="500px"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Posts",

  data() {
    return {
      items: [
        {
          id: 1,
          title: 'איטום גגות ע"י יריעות ביטומניות',
          subtitle: "מאת מיכאל יוגב",
          description:
            'האיטום הפופולרי והאמין ביותר בעבודות איטום גגות הוא איטום ביריעות ביטומניות. איטום גגות ע"י יריעות ביטומניות מחזיק מעמד נגד חדירת מים טוב יותר מכל סוג איטום אחר.איטום גגות ביריעות ביטומניות מחזיק מעמד...',
          src: "/assets/carousel/2.jpg",
          to: "/yeriot",
        },
        {
          id: 2,
          title: "זיפות גגות במרכז",
          subtitle: "מאת מיכאל יוגב",
          description:
            "זיפות גגות עמיד לחדירת מים לפחות 5 שנים בתנאי שמסיידים את הגג עם חיזוק של דבק בכל שנה. סיוד הגג הוא חובה כדי למנוע מקרני השמש לייבש את הזפת השחור הרגיש לקרני השמש, ונוטה להיסדק ולהתייבש בשמש...",
          src: "/assets/ViewHero/ZiputGagot.jpg",
          to: "/roof-tarring",
        },
        {
          id: 3,
          title: "איטום קירות חיצוניים בסנפלינג",
          subtitle: "מאת מיכאל יוגב",
          description:
            'במיכאל איטום אנחנו עושים את כל העבודות של האיטום בסנפלינג כגון ריסוס חמרי איטום שדוחים מים ע"י איטום קיר חיצוני בסנפלינג,פתיחה ואיטום סדקים על הקיר החיצוני ע"י סיקה פלקס או חמרי איטום אלסטומריים אשר מתאימים...',
          src: "/assets/carousel/3.jpg",
          to: "/exposed-walls",
        },
        {
          id: 4,
          title: "איטום בסופר לסטיק 560",
          subtitle: "מאת מיכאל יוגב",
          description:
            "איטום גגות בסופר לסטיק 560 טיקסו של חברת סיקה שוויצריה מלבין את הגג וחוסך סיוד כל שנה. סופר לסטיק 560 הוא חומר איטום אלסטי ומגשר סדיקה, עמיד UV ובעל עמידות גבוהה לתנאי סביבה קשים עם כושר היצמדות חזק...",
          src: "/assets/ViewHero/Sica560.jpg",
          to: "/lastik-560",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.logo-img {
  opacity: 0.3;
}
.posts-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.posts-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 280px;
  margin: auto;
}
.latestPostBlock {
  background: darken(white, 2%);
}
.v-card__title {
  background: rgba(0, 0, 0, 0.3);
}
.postimg {
  transition: transform 0.5s ease;
}
/* .postimg:hover {
  transform: scale(1.15);
}
.img-div {
  overflow: hidden;
}
text-div {
  transform: scale(1) !important;
} */
</style>
