<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col cols="12" md="6" align-self="center">
          <v-carousel class="mt-12" cycle hide-delimiters>
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Header -->
          <div style="text-align: center; max-width: 300px" class="">
            <h1 class="page-title">איטום ביריעות</h1>
          </div>
          <!-- Paragraph -->האיטום הפופולרי והאמין ביותר בעבודות איטום גגות הוא
          איטום ביריעות ביטומניות. <br />
          הסיבה המרכזית לכך היא שגגות שנאטמו על ידי יריעות ביטומניות עמידים יותר
          בפני חדירת מים מאשר כל סוג איטום אחר. <br />
          בהתאם כאשר אנו אוטמים גג ביריעות ביטומניות אנו מספקים תעודת אחריות נגד
          חדירת מים ל-10 שנים (למעשה היריעות יכולות להחזיק אפילו 20 ל 30
          שנה).<br />

          בין היתרונות הנוספים של יריעות ביטומניות נכללים בידוד של חום המונע
          מחלל הבית שמתחת לגג להתחמם יתר על המידה בקיץ, ואין צורך לבצע עבודות
          תחזוקה בגג כמו סיוד.<br />
          חשוב להדגיש שלא כל סוגי היריעות הביטומניות מאפשרות איטום יעיל
          וארוך-טווח שכזה.<br />
          במיכאל איטום אנו משתמשים ביריעות ביטומניות בעלות תו-תקן 5 מילימטר
          המיוצרות על ידי פזקר בלבד. אלו היריעות האיכותיות והאמינות ביותר,
          ובידיים מיומנות הן מאפשרות את הרמה הגבוהה ביותר של איטום בניין.<br />
          האיטום ביריעות כולל מספר שלבים, ובפרט שלב ההלחמה דורש מיומנות בכדי
          להגיע לאטימה אופטימלית, בהתאם במיכאל איטום כל אחד משלבי האיטום מבוצע
          אך ורק על ידי אוטמים בעלי עשר שנות ניסיון לפחות. <br /> <br/>
          <strong>

          שלבי אטימת גג עם יריעות ביטומניות:<br />
          </strong>
          אם תשתית הגג היא בטון, מורחים פריימר לפני ביצוע האיטום, כדי שניתן יהיה
          לקשר בין גג הבטון ליריעה הביטומנית. עוד לפני ביצוע האיטום, מרימים
          מזגנים, קולטי שמש וכו', וממקמים אותם על משטח מוגבה למניעת מגע עם
          היריעות.<br />

          איטום ביריעות ביטומניות נעשה על ידי פרישה-הדרגתית של היריעות תוך-כדי
          הלחמה באמצעות ערכת הלחמה ניידת (ברנר) אשר ממיסה את היריעות הביטומניות
          ומלחימות אותן לגג. נדרש דיוק ומומחיות בשימוש בערכת ההלחמה כך שהיריעה
          תגיע לטווח המעלות האופטימלי להלחמה לגג – אם לא יהיו חמות מספיק ההלחמה
          לגג לא תהיה הדוקה, אך אם יתחממו מדי הן עלולות להישרף ובכך לאבד
          מאטימותן.<br />

          היריעות הביטומניות מולחמות אחת על השניה בחפיפה של 10 ס"מ והופכות למקשה
          אחת המונעת חדירת מים. תשומת לב מיוחדת ניתנת למעקה התחתון בדפנות הגג
          (רולקות) אשר יש להקפיד לאטום עם יריעות ביטומניות שכן אזור זה רגיש
          במיוחד לחדירת מים.
        </v-col>
      </v-row>
    </v-container>
    <Contact />

    <Posts />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import Posts from "../components/Posts";
export default {
  name: "RoofSealing",
  components: {
    Contact,

    Posts,
  },

  data() {
    return {
      items: [
        {
          src: "/assets/ViewHero/YeriotBitumeniot/1.jpg",
        },
        {
          src: "/assets/ViewHero/YeriotBitumeniot/2.jpg",
        },
        {
          src: "/assets/ViewHero/YeriotBitumeniot/3.jpg",
        },
      ],
    };
  },
  computed: {},
};
</script>

<style>
.page-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.page-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 160px;
  margin: auto;
}
</style>
