<template>
  <v-container>
    <v-footer padless>
      <v-card
        flat
        tile
        class="lighten-1 text-center"
        width="100%"
        color="transparent"
      >
        <v-card-text class="pt-0">
          מייל ליצירת קשר
          <strong> mail@michaelitum.co.il </strong>
          מספר טלפון
          <strong> 052-570-6611 </strong>
          <br />
          <strong> מיכאל איטום. </strong>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="">
          {{ new Date().getFullYear() }}

          - נבנה ע"י - <strong>dolevbitran@gmail.com</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {
      items: [],
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    };
  },
  computed: {},
};
</script>

<style></style>
