var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-navigation-drawer',{attrs:{"app":"","absolute":"","right":"","temporary":"","hide-overlay":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":"/assets/avatar.jpg"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("מיכאל איטום")]),_c('v-list-item-subtitle',[_vm._v("איטום קירות וגגות")])],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":"","dark":""}},[_vm._l((_vm.links),function(link,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":link.to}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"black"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_vm._v(_vm._s(link.title))])],1)],1)}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$vuetify.goTo('#contact', {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
    })}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"black"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_vm._v(" צור קשר ")])],1)],1)],2)],1),_c('v-toolbar',{staticStyle:{"overflow":"hidden"},attrs:{"flat":"","hide-on-scroll":""}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-lg-and-up",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-side-icon',[_c('v-img',{attrs:{"src":"/assets/logo.jpg","title":"Logo","max-width":"200px"}})],1),_c('v-toolbar-items',{staticStyle:{"width":"100%"}},[_c('v-tabs',{staticClass:"navtabs hidden-md-and-down",attrs:{"color":"#5068a9","active-class":"activetab","slider-color":"transparent","height":"100%","align-with-title":""}},[_vm._l((_vm.links),function(link,i){return _c('v-tab',{key:i,attrs:{"to":link.to},on:{"click":function($event){return _vm.$vuetify.goTo(1, {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
    })}}},[_vm._v(" "+_vm._s(link.title)+" ")])}),_c('v-btn',{attrs:{"text":"","tile":"","large":"","height":"100%","color":"#757575"},on:{"click":function($event){return _vm.$vuetify.goTo('#contact', {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
    })}}},[_vm._v(" צור קשר ")])],2),_c('v-spacer'),_c('div',{staticClass:"hidden-sm-and-down",staticStyle:{"width":"100px","display":"flex","flex-direction":"column","justify-content":"center","color":"#5c8ece"}},[_c('h5',[_vm._v(" עם ישראל ")]),_c('h5',[_vm._v(" ביחד ננצח ")])]),_c('img',{staticClass:"hidden-sm-and-down",staticStyle:{"align-self":"center"},attrs:{"src":"/assets/Israel_flag.svg","height":"60%"}}),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"#5068a9","href":"tel:+972 52-570-6611","text":""}},[_c('div',{staticClass:"hidden-sm-and-down",style:({fontSize: '2rem' })},[_vm._v("052-570-6611")]),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-phone ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }