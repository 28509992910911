<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col cols="12" md="6">
          <v-img
            src="/assets/ViewHero/Sica560.jpg"
            max-height="600"
            title="סיקה560"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Header -->
          <div style="text-align: center; max-width: 350px" class="">
            <h1 class="super560-title">איטום גגות בסיקה לסטיק 560</h1>
          </div>
          <!-- Paragraph -->
          איטום גגות בסופר לסטיק 560 של חברת סיקה מלבין את הגג וחוסך סיוד כל
          שנה.<br />
          סופר לסטיק 560 הוא חומר איטום אלסטי ומגשר סדיקה, עמיד UV ובעל עמידות
          גבוהה לתנאי סביבה קשים עם כושר היצמדות חזק, המאפשר ביצוע איטום על
          מגוון תשתיות: בטון, אגרגט של יריעות ביטומניות, ביטומן האחריות על איטום
          גגות בסופר לסטיק 560 היא בין 10 ל 15 שנים.<br />
          איטום גגות בסופר לסטיק 560 מיושם במריחה או בהתזה.<br />
          <strong>
            <br/>
          שלבי אטימת גג בסופר לסטיק 560:
          </strong>
          <br /><br />
          לפני ביצוע האיטום מנקים את הגג ודואגים שהמשטח נקי. לפני עבודת איטום גג
          בסיקה לסטיק 560 מקלפים אזורים פגומים,<br />
          תשומת לב מיוחדת ניתנת למעקה התחתון בדפנות הגג (רולקות) אשר יש להקפיד
          לאטום עם סופר לסטיק 560 שכן אזור זה רגיש במיוחד לחדירת מים
          <br /><br />
          איטום מקצועי בסיקה לסטיק 560 הוא איטום כפול שכבה ראשונה מדוללת בהרבה
          מים ושכבה שנייה מדוללת בקצת מים.
        </v-col>
      </v-row>
    </v-container>
    <Contact />

    <Posts />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import Posts from "../components/Posts";
export default {
  name: "Super560",
  components: {
    Contact,

    Posts,
  },

  data() {
    return {};
  },
  computed: {},
};
</script>

<style>
.super560-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.super560-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 290px;
  margin: auto;
}
</style>
