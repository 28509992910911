<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col cols="12" md="6">
          <v-img
            src="/assets/roof-sealing.jpg"
            max-height="500px"
            title="איטום גגות"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Header -->
          <div style="text-align: center; max-width: 250px" class="">
            <h1 class="page-title">זיפות גגות</h1>
          </div>
          <!-- Paragraph -->
          איטום גגות בסופר לסטיק 560 טיקסו של חברת סיקה שוויצריה מלבין את הגג
          וחוסך סיוד כל שנה. סופר לסטיק 560 הוא חומר איטום אלסטי ומגשר סדיקה,
          עמיד UV ובעל עמידות גבוהה לתנאי סביבה קשים עם כושר היצמדות חזק. כמו כן
          איטום גגות בסופר לסטיק 560 מתבצע במגוון רחב של תשתיות: לבטון, לאגרגט
          של יריעות ביטומניות, לביטומן עם פריימר וכד'. האחריות על איטום גגות
          בסופר לסטיק 560 היא ל 5 שנים. איטום גגות בסופר לסטיק 560 מיושם במריחה
          או בהתזה. בגגות שיש עליהם דודי שמש, מזגנים וכד' יש להרים את המזגנים
          ואת קולטי השמש וכד' לפני ביצוע איטום גגות בבסופר לסטיק 560 ולאחר מכן
          להניח את המזגנים וקולטי השמש וכד' על אריחים בגובה כדי למנוע מהם לגעת
          בחומר האיטום ולהזיק לו. לפני עבודת איטום גג בבסופר לסטיק 560 מקלפים
          אזורים פגומים, וחורצים סדקים, ואוטמים את האזורים המועדים לחדירת מים
          ע"י בסופר לסטיק 560 ועליה רשת בד, ועל הרשת איטום בשכבת סופר לסטיק 560
          נוספת. איטום גגות בסופר לסטיק 560 מתבצע גם על החיבור בין המעקה לגג
          בגובה של כ 15 ס"מ על המעקה.
        </v-col>
      </v-row>
    </v-container>
    <Contact />

    <Posts />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import Posts from "../components/Posts";
export default {
  name: "RoofSealing",
  components: {
    Contact,

    Posts,
  },

  computed: {},
};
</script>

<style>
.ttt {
  font-family: "Rubik" !important;
  font-weight: "700";
  font-size: 1.5rem;
}
.page-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.page-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 160px;
  margin: auto;
}
</style>
