var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"my-10"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('h1',{staticClass:"posts-title text-center"},[_vm._v("השירותים שלנו")])]),_c('v-row',{staticClass:"mt-16"},[_vm._l((_vm.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","md":"4","justify":"center","align":"center"}},[_c('v-card',{class:'mx-2' + _vm.isMobile ? '' : 'mb-16',attrs:{"max-width":"400px","outlined":"","hover":""}},[_c('div',{staticClass:"img-div"},[_c('v-img',{staticClass:"white--text align-end postimg",attrs:{"height":"200px","src":item.src,"title":item.title}},[_c('v-card-title',{staticClass:"text-div"},[_vm._v(_vm._s(item.title))])],1)],1),_c('v-card-subtitle',{staticClass:"pb-0 text-right"},[_vm._v(_vm._s(item.subtitle))]),_c('v-card-text',{staticClass:"text--primary text-right"},[_c('div',[_vm._v(_vm._s(item.description))])]),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-auto",attrs:{"color":"primary","text":""},on:{"click":() => {
                  _vm.$router.push(item.to);
                  _vm.$vuetify.goTo(1, {
                    duration: 300,
                    offset: 0,
                    easing: 'easeInOutCubic',
                  });
                }}},[_vm._v(" המשך לקרוא ")])],1)],1)],1)}),_c('v-col',{attrs:{"cols":"12","md":"4","justify":"center","align":"center"}}),_c('v-col',{attrs:{"cols":"12","md":"4","justify":"center","align":"center"}},[_c('div',{staticClass:"img-div"},[_c('v-img',{staticClass:"logo-img",attrs:{"src":"/assets/linkedin_banner_image_2.png","height":"500px"}})],1)])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }