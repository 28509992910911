<template>
  <div>
    <v-container class="mt-10">
      <!-- Header -->
      <v-row justify="center" align="center">
        <h1 class="testimonials-title">אתם ממליצים</h1>
        <v-img
          src="/assets/thumbs_up.svg"
          class="mb-4 mr-4"
          max-height="60px"
          max-width="60px"
        />
      </v-row>
      <!-- Testimonials Carousel -->
      <carousel-3d :autoplay="true" :autoplay-timeout="5000" class="my-16">
        <slide
          v-for="(item, i) in items"
          class=""
          style="border-style: none; background-color: transparent"
          :key="i"
          :index="i"
        >
          <v-card hover width="90%" class="my-auto mx-auto">
            <v-row justify="center" align="center" class="mt-1">
              <v-avatar size="70" class="mt-4">
                <img :src="item.src" title="John" />
              </v-avatar>
            </v-row>
            <v-row justify="center" align="center"> {{ item.name }} </v-row>
            <v-row justify="center" align="center" class="">
              <v-card-text>
                <v-row align="center" class="mx-0" justify="center">
                  <v-rating
                    :value="5"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>

                  <div class="grey--text ml-4 mr-2">5</div>
                </v-row>
              </v-card-text>
              <div class="text-right my-3" style="max-width: 200px">
                "{{ item.review }}"
              </div>
            </v-row>
          </v-card>
        </slide>
      </carousel-3d>
    </v-container>
    <Contact />

    <Posts />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import Posts from "../components/Posts";
export default {
  name: "Testimonials",
  components: {
    Contact,

    Posts,
  },

  data() {
    return {
      items: [
        {
          src: "/assets/Testimonials/Carmela_Falah.jpg",
          name: "כרמלה פלח",
          review: "גבר מקצועי ומאוד נחמד ציון 10",
        },
        {
          src: "/assets/Testimonials/Daria_Mamchen.jpg",
          name: "Daria Mamchen",
          review: "10/10!!! מהיר, מקצועי, אמין. בקיצור מומלץ",
        },
        {
          src: "/assets/Testimonials/Dor_Dow_Efraim.jpg",
          name: "Dor Dow Efraim",
          review: "תותח מיקצועי ובעל לב זהב",
        },
        {
          src: "/assets/Testimonials/Ori_Lapushner.jpg",
          name: "Ori Lapushner",
          review: "עשה אצלי זיפות גג, בחור מקצועי ונתן גם אחריות.",
        },
        {
          src: "/assets/Testimonials/Royi_Bitton.jpg",
          name: "Royi Bitton",
          review:
            "מקצוען אמיתי ויותר מזה איש ישר וכן מצרך נדיר בימנו ממליץ מאודדד",
        },
        {
          src: "/assets/Testimonials/Amir_Assouline.jpg",
          name: "Amir Assouline",
          review: "עושים עבודה מקצועית עם לב רחב",
        },
        {
          src: "/assets/Testimonials/Lior_Trosman.jpg",
          name: "Lior Trosman",
          review:
            "בא אליי הביתה אחרי כמה הצעות מחיר, הכי אמין שהיה לי! עשה עבודה מקצועית וגם לא קרע לי את הכיס",
        },
        {
          src: "/assets/Testimonials/Roi_Dror.jpg",
          name: "Roi Dror",
          review:
            "מקצועי ואמין במחיר הגון , ממליץ בחום עשה לי עבודה מצויינת :)",
        },
      ],
    };
  },
  computed: {},
};
</script>

<style>
.testimonials-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.testimonials-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 150px;
  margin: auto;
}
</style>
