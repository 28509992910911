<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col cols="12" md="6">
          <video-background
            src="\assets\ViewHero\exposed_vid.mp4"
            style="max-height: 550px; height: 100vh"
            title="איטום קירות חיצוניים"
          >
          </video-background>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Header -->
          <div style="text-align: center; max-width: 400px" class="">
            <h1 class="exposed-title">איטום קירות חיצוניים בסנפלינג</h1>
          </div>
          <!-- Paragraph -->
          איטום בסנפלינג מהווה תחליף יעיל וזול לעבודה עם מנוף.<br />
          במיכאל איטום אנחנו מבצעים את כל סוגי עבודות האיטום בסנפלינג:
          <br /><br />
          א. ריסוס חמרי איטום שדוחים מים ע"י איטום קיר חיצוני בסנפלינג.<br />
          ב. פתיחה ואיטום סדקים על הקיר החיצוני ע"י סיקה פלקס או חמרי איטום
          אלסטומריים המותאמים ביכולת ההידבקות שלהם לתשתית הבניין הקיימת.<br />
          ג. איטום קירות חיצוניים בצמנט .<br />
          ד. התקנת מרזבים או תיקונם.<br />
          ה. מריחת מניעתית של חומרי איטום אלסטומריים על קירות מועדים לחדירת מים.
          <br /><br />
          איטום קיר חיצוני בסנפלינג לא מצריך תחזוקה שנתית.
          <br /><br />
          שנות האחריות על עבודות איטום קירות חיצוניים בסנפלינג משתנות בהתאם לסוג
          העבודה והתשתית הקיימת.
        </v-col>
      </v-row>
    </v-container>
    <Contact />

    <Posts />
  </div>
</template>

<script>
import Contact from "../components/Contact";
import Posts from "../components/Posts";
export default {
  name: "ExposedWalls",
  components: {
    Contact,
    Posts,
  },

  data() {
    return {
      items: [],
    };
  },
  computed: {},
};
</script>

<style>
.exposed-title {
  font-size: 3rem;
  font-weight: 400;
  color: #5068a9;
  margin-bottom: 20px;
}
.exposed-title::after {
  content: "";
  background: #5068a9;
  display: block;
  height: 3px;
  width: 280px;
  margin: auto;
}
</style>
