import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import Carousel3d from "vue-carousel-3d";
import VideoBackground from "vue-responsive-video-background-player";

Vue.component("video-background", VideoBackground);
axios.defaults.baseURL = "https://formspree.io/f/xjvpzdvr";

Vue.use(Carousel3d);
new Vue({
  router,
  store,
  vuetify,
  mounted() {
    this.$vuetify.rtl = true;
  },
  render: (h) => h(App),
}).$mount("#app");
