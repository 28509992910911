<template>
  <div
    style="
      background-image: url('https://wallpaperaccess.com/full/1093402.jpg');
      background-size: cover;
    "
  >
    <!-- <v-container align="center"> -->
    <v-card
      width="100%"
      class="mt-1"
      flat
      color="rgb(0, 0, 0, 0.3)"
      style="overflow: hidden"
    >
      <div>
        <v-row class="" align="center" justify="center">
          <v-col
            cols="12"
            md="6"
            class="py-0 hidden-sm-and-down"
            align="center"
            justify="center"
          >
            <img
              src="/assets/About.jpg"
              title="Michael Yogev"
              height="470px"
              class=""
            />
          </v-col>
          <v-col cols="12" md="6" align="center" justify="center" class="zzz">
            <div class="zzz" style="text-align: center; max-width: 80%">
              <v-row align="center" :class="isMobile ? 'my-2' : ''">
                <div style="text-align: center; max-width: 250px" class="">
                  <h1 class="name-title">מיכאל איטום</h1>
                </div>
                <v-avatar size="70" class="mr-5 ml-5 hidden-md-and-up">
                  <v-img src="/assets/avatar.jpg" Title="Michael"></v-img>
                </v-avatar>
              </v-row>

              <div
                :style="isMobile ? 'width: 80%;' : 'width: 80%;'"
                align-center
                justify-center
                class=""
              >
                <p
                  class="pb-3 mx-auto text-right white--text"
                  style="font-size: 0.9rem"
                >
                  <span style="font-size: 1rem; font-weight: 500">
                    מיכאל יוגב בעל עשר שנות ניסיון באיטום גגות ומבנים, ודור
                    שלישי לאוטמי גגות שהתחילו את פעילותם ב-1968.
                    <br />
                    מבצע עבודות במחוז תל אביב ומחוז מרכז, באיכות איטום
                    בלתי-מתפשרת המגובה באחריות מלאה לכל עבודה עד ל-10 שנים בעזרת צוות כחול לבן.
                    <br />
                    <br />
                    מומחה לעבודות איטום גגות, יריעות ביטומניות, זיפות גגות,
                    ואיטום קירות חיצוניים. ובפרט, מומחה בשימוש אופטימלי בחומרי
                    איטום ביטומניים, פוליארטניים, אקריליים, וצמנטיים-גמישים.
                  </span>
                  <br />
                  <br />

                  *מאוד חשוב לקחת אנשי מקצוע שחיים בתחום ומתחייבים לאחריות בכתב
                  בשביל להימנע מהוצאות מיותרות ועבודות שיכולות אפילו לעשות נזק!

                  <br />
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row justify="center">
        <!-- <v-container
          align-center
          justify-center
          width="10%"
          style="background-color: transparent"
        > -->
        <!-- </v-container> -->
      </v-row>
    </v-card>
    <!-- </v-container> -->
  </div>
</template>

<script>
export default {
  name: "About",

  data() {
    return {
      items: [
        {
          src: "/assets/carousel/1.png",
          title: "10 שנות אחראריות!",
        },
        {
          src: "/assets/carousel/2.png",
          title: "משנת 1968, דור שלישי לאיטום",
        },
        {
          src: "/assets/carousel/3.png",
          title: "10 שנות אחראריות!",
        },
        {
          src: "/assets/carousel/4.png",
          title: "10 שנות אחראריות!",
        },
      ],
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.name-title {
  font-size: 3rem;
  font-weight: 200;
  color: white;
  margin-bottom: 20px;
}
.name-title::after {
  content: "";
  background: white;
  display: block;
  height: 2px;
  width: 160px;
  margin: auto;
}
</style>
